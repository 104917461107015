/*
 * @Date: 2022-04-24 11:43:07
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-10 17:19:41
 * @description: file content
 * @FilePath: /case-platform-frontend/src/plugins/constans.js
 */
export const FIELD_TYPE = {
  [1]: '多行文本',
  [2]: '单选',
  [3]: '多选',
  [4]: '日期',
  [5]: '上传文件',
  [6]: '数字',
}
export const FIELD_TYPE_LIST = {
  multilineText: 1,
  radio: 2,
  checkbox: 3,
  date: 4,
  uploadFile: 5,
  number: 6,
}

export const HTTP_CODE = {
  NOT_LOGIN: 1002
}