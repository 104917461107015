<!--
 * @Date: 2022-04-20 14:16:43
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 20:32:46
 * @description: file content
 * @FilePath: /case-platform-frontend/src/components/MenuList.vue
-->
<template>
  <div class="menu-container">
    <el-menu
      :default-active="activeIndex"
      unique-opened
      class="el-menu-vertical-demo"
      :class="{'width-style': getIsExpand}" :collapse="getIsExpand" active-text-color="#4575E5">
      <!-- <div  v-for="item in menulist" :key="item.id"> -->
        <el-submenu v-for="item in menulist" :key="item.id" v-show="item.isSubMenu && item.isShow" :index="item.id">
          <template slot="title">
            <i :class="item.iconClass"></i>
            <span slot="title">{{ item.menuName }}</span>
          </template>
          <el-menu-item
            v-for="subMenu in item.subMenu"
            :key="subMenu.path"
            :index="subMenu.id"
            @click.native="handleSelect(subMenu)">
            {{ subMenu.menuName }}
          </el-menu-item>
        </el-submenu>
        <!-- <el-menu-item v-for="item in menulist" :key="item.id" v-show="!item.isSubMenu" :index="item.id">
          <i class="el-icon-menu"></i>
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item> -->
      <!-- </div> -->
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const menulist = [
  {
    menuName: '案件管理',
    iconClass: 'icon-anjiantaizhang',
    id: '1',
    subMenu: [
      // {
      //   menuName: '流程管理',
      //   iconClass: '',
      //   id: '1-1',
      // },
      {
        menuName: '案件台账',
        iconClass: '',
        id: '1-2',
        path: '/home/standing'
      }
    ],
    isSubMenu: true
  },
  // {
  //   menuName: '数据看板',
  //   iconClass: '',
  //   id: '2',
  // },
  // {
  //   menuName: '智能比对',
  //   iconClass: '',
  //   id: '3',
  // },
  {
    menuName: '后台管理',
    iconClass: 'icon-houtaiguanli',
    id: '4',
    subMenu: [
      {
        menuName: '权限配置',
        iconClass: '',
        id: '4-1',
        path: '/manage/authConfig'
      },
      {
        menuName: '字段配置',
        iconClass: '',
        id: '4-2',
        path: '/manage/fieldConfig'
      }
    ],
    isSubMenu: true
  },
]
const roleList = {
  admin: 1,
  default: 2
}
export default {
  props: {
    isExpand: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getIsExpand','getUserInfo'])
  },
  data() {
    return {
      isCollapse: false,
      menulist,
      activeIndex: '1-2'
    }
  },
  watch: {
    isExpand(newVal) {
      this.isCollapse = newVal
    },
    getUserInfo(newVal) {
      this.menulist = _.map(this.menulist, list => {
        list.isShow = true
        list.role = newVal.role
        if (list.role === roleList.default && list.menuName === '后台管理') {
          list.isShow = false
        }
        return list
      })
    }
  },
  mounted() {
    this.setDefaultMenu()
  },
  methods: {
    setDefaultMenu() {
      const path = this.$route.path
      _.forEach(this.menulist, menu => {
        if(menu.isSubMenu) {
          _.forEach(menu.subMenu, sub => {
            if(sub.path === path) {
              this.activeIndex = sub.id
            }
          })
        }
      })
    },
    handleSelect({path, id}) {
      this.activeIndex = id
      if(path === this.$route.path){
        return
      }
      this.$router.push({
        path
      })
    },
    
  }
}
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo {
  width: 201px;
  height: calc(100vh - 88px)
}
.width-style {
 width: 64px;
}
.el-menu-item.is-active {
  background: rgba(69,117,248, .2);
}
</style>