/*
 * @Date: 2022-04-19 11:59:40
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-16 17:14:50
 * @description: file content
 * @FilePath: /case-platform-frontend/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    isExpand: false
  },
  getters: {
    getUserInfo(store) {
      return store.userInfo
    },
    getIsExpand(store) {
      return store.isExpand
    }
  },
  mutations: {
    updateUserInfo(store, userInfo) {
      store.userInfo = userInfo
      localStorage.setItem('user', JSON.stringify(userInfo))
    },
    updateIsExpand(store, isExpand) {
      store.isExpand = isExpand
    },
  },
  actions: {
    updateUserInfo({ commit }, userInfo) {
      commit('updateUserInfo', userInfo)
    },
    updateIsExpand({ commit }, isExpand) {
      commit('updateIsExpand', isExpand)
    },
  },
  modules: {},
})
