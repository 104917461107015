<template>
  <component class="flex-row" :is="tag">
    <slot></slot>
  </component>
</template>

<script lang="ts">
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  }
}
</script>
