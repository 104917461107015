/*
 * @Date: 2022-04-20 16:49:25
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-04-22 11:37:55
 * @description: file content
 * @FilePath: /case-platform-frontend/src/views/manage/router.js
 */

// Index
const Index = () => import('@/views/Index.vue')
// 权限配置
const AuthSetting = () => import('./auth-config/AuthSetting.vue')
// 字段配置
const FieldList = () => import('./field-config/FieldList.vue')

export default {
  path: '/manage',
  name: 'Index',
  component: Index,
  redirect: 'manage/authConfig',
  children: [
    {
      path: 'authConfig',
      component: AuthSetting
    },
    {
      path: 'fieldConfig',
      component: FieldList
    },
  ]
}
