<!--
 * @Date: 2022-04-19 11:59:40
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 20:31:32
 * @description: file content
 * @FilePath: /case-platform-frontend/src/App.vue
-->
<template>
  <div id="app">
    <index v-if="isLogin"></index>
    <router-view v-else />
  </div>
</template>

<script>
import Index from '@/views/Index.vue'
export default {
  components: {
    Index
  },
  computed: {
    isLogin() {
      let { fullPath } = this.$route
      return false
    }
  }
}
</script>
<style lang="scss">
@import './style/index.scss'; // 全局自定义的css样式
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  font-size: 0.875rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
}
</style>
