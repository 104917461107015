/*
 * @Date: 2022-04-19 11:59:40
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-04-22 11:39:24
 * @description: file content
 * @FilePath: /case-platform-frontend/src/main.js
 */
import Vue from "vue";
import App from "./App.vue";
import '@/plugins/vue-inject'
import router from "./router";
import store from "./store";
import '@/assets/font.js'
import Element from 'element-ui'
import './style/element-variables.scss'

Vue.use(Element)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
