/*
 * @Date: 2022-04-19 11:59:40
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-11 00:16:21
 * @description: file content
 * @FilePath: /case-platform-frontend/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/user/Login.vue'
import Index from '../views/Index.vue'
// 案件台账
import standingList from '@/views/case-manage/case-standing/router.js'
const EditCaseDetail = () => import('@/views/case-manage/case-standing/EditCaseDetail.vue')
const CreateCase = () => import('@/views/case-manage/case-standing/CreateCase.vue')
// 后台管理 
import backendManager from '@/views/manage/router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home/standing',
    component: Index,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Index',
    component: Index,
    children: [
      standingList,
      {
        path: 'editCaseDetail',
        name: 'EditCaseDetail',
        component: EditCaseDetail,
      },
      {
        path: 'createCase',
        name: 'CreateCase',
        component: CreateCase,
      }
    ]
  },
  backendManager
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
