import axios from 'axios'
import _ from 'lodash'
import path from 'path'
import Vue from 'vue'
// import router from '@/router/index'

import { HTTP_CODE } from '@/plugins/constans'

import store from '../store'

const http = axios.create({
  baseURL: process.env.BASE_URL,
}) // show error

http.interceptors.request.use(
  async (config) => {
    const method = config.method.toUpperCase()
    config.headers = {
      ...config.headers,
      ['X-HTTP-Method-Override']: method,
    }
    if (method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
      config.method = 'post'
    }
    return config
  },
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  (error) => {
    console.error('request error:', error)
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    return response
  },
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  async (error) => {
    const data = await formatError(error)
    if (_.isEmpty(data)) {
      return Promise.reject(error)
    }
    if (data.code === HTTP_CODE.NOT_LOGIN) {
      // if (
      //   !_.includes(location.pathname, '/login')
      // ) {
        // router.push('/login')
        location.href = location.origin + '/login'
      // }
    } else {
      console.log('http error', data)
      const msg = data.message || data
      Vue.prototype.$alert(msg, '', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning',
      })
    }
    return Promise.reject(error)
  }
)

const httpNoAlert = axios.create({
  baseURL: process.env.BASE_URL,
}) // reject error with no alert

httpNoAlert.interceptors.request.use(
  (config) => {
    const method = config.method.toUpperCase()
    config.headers = {
      ...config.headers,
      token: store.state.token,
      ['X-HTTP-Method-Override']: method,
    }
    if (method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
      config.method = 'post'
    }
    return config
  },
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  (error) => {
    console.error('request error:', error)
    return Promise.reject(error)
  }
)

// eslint-disable-next-line promise/prefer-await-to-callbacks
httpNoAlert.interceptors.response.use(null, async (error) => {
  const data = await formatError(error)
  return Promise.reject(data)
})

async function formatError(error) {
  if (error.request.responseType === 'blob') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        error.response.data = JSON.parse(String(reader.result))
        resolve(error.response.data)
      }
      reader.onerror = () => {
        reject(error)
      }
      reader.readAsText(error.response.data)
    })
  }
  if (!error.response) {
    if (
      _.startsWith(error.message, 'net::ERR') ||
      _.startsWith(error.message, 'Network Error')
    ) {
      return {
        message: `无法连接服务器，请检查网络后重试(${error.message})`,
      }
    }
  }
  error.response = error.response || {}
  return error.response.data || {}
}

export { http, httpNoAlert }
