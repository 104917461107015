/*
 * @Date: 2022-04-21 18:50:48
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-05 17:30:13
 * @description: file content
 * @FilePath: /case-platform-frontend/src/plugins/vue-inject.js
 */
/* eslint-disable promise/prefer-await-to-callbacks */
import _ from 'lodash'
import Vue from 'vue'

import column from '@/components/Column.vue'
import row from '@/components/Row.vue'

import { http, httpNoAlert } from './axios'
const components = {
  column,
  row,
}

_.forIn(components, (val, key) => {
  Vue.component(key, val)
})

_.extend(window, {
  _,
  $http: http,
  Vue
})

_.extend(Vue.prototype, {
  _,
  $http: http,
  $httpNoAlert: httpNoAlert,
})
