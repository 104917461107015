<!--
 * @Date: 2022-05-10 16:47:04
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 21:51:22
 * @description: file content
 * @FilePath: /case-platform-frontend/src/components/LogOut.vue
-->
<template>
  <row class="logout-box">
    <row class="text" @click.native="logout">
      <i class="icon-tuichu"></i>
      <p v-if="!isExpand">退出系统</p>
    </row>
  </row>
</template>

<script>
import { logoutApi } from '@/views/user/api'
export default {
  props: {
    isExpand: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async logout() {
      await logoutApi()
      this.$message.success('退出成功')
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style lang="scss" scoepd>
.logout-box {
  width: 200px;
  cursor: pointer;
  font-size: 14px;
  color: $--color-blank;
  padding-left: 20px;
  border: 1px solid $--color-border-separator;
  .text {
    height: 40px;
    align-items: center;
  }
}
</style>