<!--
 * @Date: 2022-04-20 15:09:21
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 20:32:11
 * @description: file content
 * @FilePath: /case-platform-frontend/src/components/Header.vue
-->
<template>
  <div class="flex-row header-container">
    <div class="flex-row left-row" :class="{'isExpand': getIsExpand}">
      <img src="@/assets/image/bg-logo.png" alt="logo">
      <p class="title-text" v-if="!getIsExpand">智慧案件平台</p>
    </div>
    <div class="flex-row right-row" v-if="isHeaderShow">
      <i class="icon-font" :class="getIsExpand ? 'icon-zhankai' : 'icon-shouqi'" @click="clickHandle"></i>
      <p class="title-text" v-if="getIsExpand">智慧案件平台</p>
      <div class="flex-row user-info">{{ userInfo.name }}</div>
      <Avatar :user="{name: userInfo.name}"></Avatar>
    </div>
  </div>
</template>

<script>
import { getUserInfoApi } from '@/views/user/api'
import Avatar from '@/components/Avatar.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Avatar
  },
  data() {
    return {
      userInfo: {},
      isExpand: false
    }
  },
  computed: {
    ...mapGetters(['getIsExpand']),
    isHeaderShow() {
      let path = this.$route.path
      return path !== '/manage/fieldConfig'
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      let { data: {
        data
      } } = await getUserInfoApi()
      this.userInfo = data
      this.$store.dispatch('updateUserInfo', data)
    },
    clickHandle() {
      this.isExpand = !this.isExpand
      this.$store.dispatch('updateIsExpand', this.isExpand)
      this.$emit('isExpandHandle', this.isExpand)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  height: 48px;
  padding-right: 20px;
  .left-row {
    width: 201px;
    align-items: center;
    border-right: 1px ;
    box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
    img {
      width: 25px;
      height: 25px;
      margin: 0 10px;
    }
  }
  .title-text {
    font-size: 18px;
    font-weight: bold;
  }
  .isExpand {
    width: 64px;
  }
  .right-row {
    flex: 1;
    box-shadow: inset 0px -1px 0px #EAEDF3;
    align-items: center;
    .icon-font {
      font-size: 18px;
      cursor: pointer;
      margin-left: 10px;
    }
    .user-info {
      flex: 1;
      justify-content: right;
      color: #3D485B;
      font-size: 14px;
      margin-right: 7px;
    }
  }
}
</style>