<template>
  <div
    class="avatar-container"
    :class="{ 'review-avatar-container': isReviewState }"
    v-if="user.name || user.avatar || user.nickName"
  >
    <row
      class="wrapper"
      :style="user.avatar | bgImageStyle"
      :class="[
        user.avatar ? '' : 'default-image',
        user.status === 2 ? 'complete-border' : ''
      ]"
    >
      <span v-if="!user.avatar">{{ defaultImage }}</span>
    </row>
    <slot></slot>
  </div>
</template>

<script>
// import SvgIcon from '@/components/SvgIcon.vue'
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    sliceCount: {
      type: Number,
      default: -2
    },
    isReviewState: {
      type: Boolean,
      default: false
    },
    isCreator: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // SvgIcon
  },
  data() {
    return {}
  },
  computed: {
    defaultImage() {
      let name =
        this.user.name || this.user.nickName || this.user.userName || ''
      return name.slice(this.sliceCount)
    },
    reviewState() {
      if (this.user.state === 0) {
        return 'i-weidu'
      } else if (this.user.state === 1) {
        return 'i-shenpizhong1'
      } else return 'i-shenpi-yiqueren'
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
  background-color: #fff;
  z-index: 0;
}
.complete-border {
  border: 2px solid #7fd944;
}
.default-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $--color-primary;
  overflow: auto;
  color: #fff;
  line-height: 24px;
  justify-content: center;
  span {
    font-size: 12px;
    transform: scale(0.85);
  }
}
.review-avatar-container {
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px 0;
  padding-left: 2px;
  .state-icon {
    position: absolute;
    left: 14px;
    bottom: 6px;
  }
  .creator-identify {
    position: absolute;
    left: -1px;
    top: -2px;
  }
}
.avatar-container {
  display: flex;
  flex-shrink: 0;
  justify-items: center;
}
</style>
