<!--
 * @Date: 2022-04-19 16:14:07
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-04-20 15:08:56
 * @description: file content
 * @FilePath: /case-platform-frontend/src/views/Index.vue
-->
<template>
  <div class="flex-col">
    <Layout>
      <template #content>
        <router-view />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
export default {
  components: {
    Layout
  }
}
</script>

<style lang="scss" scoped>

</style>