<!--
 * @Date: 2022-04-19 16:14:07
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 15:39:41
 * @description: file content
 * @FilePath: /case-platform-frontend/src/components/Layout.vue
-->
<template>
  <column class="flex-col">
    <row class="header">
      <Header />
    </row>
    <row class="bottom-view">
      <row class="left">
        <column :class="{'menu-box': getIsExpand}">
          <MenuList :isExpand="getIsExpand"></MenuList>
          <Logout :isExpand="getIsExpand"></Logout>
        </column>
      </row>
      <row class="right">
        <slot name="content"></slot>
      </row>
    </row>
  </column>
</template>

<script>
import MenuList from './MenuList.vue'
import Header from './Header.vue'
import Logout from '@/components/Logout.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    MenuList,
    Header,
    Logout
  },
  data() {
    return {
      isExpand: false
    }
  },
  computed: {
    ...mapGetters(['getIsExpand']),
  },
  methods: {
    // getIsExpand(val) {
    //   this.isExpand = val
    // }
  }
}
</script>

<style lang="scss" scoped>
.bottom-view {
  height: calc(100vh - 48px);
}
.left {
  max-width: 200px;
}
.right {
  flex: 1;
  padding: 16px;
  background: #fafbff;
  overflow-x: auto;
}
.menu-box {
  width: 64px;
  transition: width 2s;
  -webkit-transition: width 2s;
}
</style>