<!--
 * @Date: 2022-04-19 11:59:40
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-17 20:37:15
 * @description: file content
 * @FilePath: /case-platform-frontend/src/views/user/Login.vue
-->
<template>
  <div class="login-container">
    <div class="login-layer">
        <p>
          <img src="@/assets/image/bg-logo.png" alt="logo">
          智慧案件平台
        </p>
        <el-form :model="form" :rules="rules" ref="ruleForm" class="form-style">
          <el-form-item label="" prop="userName">
            <el-input prefix-icon="icon-yonghu" v-model="form.userName" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="pass">
            <el-input prefix-icon="icon-mima" type="password" v-model="form.pass" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.isCheck"></el-checkbox>
            <span class="remember">记住密码</span>
          </el-form-item>
        </el-form>
        <el-button class="submit" type="primary" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import { loginApi } from './api'
export default {
  name: "Login",
  data() {
    return {
      form: {
        userName: '',
        pass: '',
        isCheck: false
      },
      rules: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    this.getFormUserInfo()
  },
  methods: {
    async login() {
      let params = {
        name: this.form.userName,
        password: this.form.pass
      }
      await loginApi(params)
      // let { data } = await getUserInfoApi()
      if (this.form.isCheck) {
        localStorage.setItem('userInfo', JSON.stringify(this.form))
      }
      this.$router.push({
        path: '/home/standing'
      })
    },
    getFormUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo) {
        this.form.userName = userInfo.userName
        this.form.pass = userInfo.pass
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-container {
  background: url('./img/bg-login.png') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .login-layer {
    width: 400px;
    border-radius: 4px;
    position: absolute;
    height: 446px;
    background: #fff;
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.1);
    left: 240px;
    bottom: 240px;
    padding: 66px 56px;
    box-sizing: border-box;
    p {
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #3D485B;
      font-weight: bold;
      img {
        width: 28px;
        height: 28px;
        margin: 0 10px;
      }
    }
    .form-style {
      margin-top: 70px;
      /deep/ .el-input__inner {
        border-radius: 5px;
      }
      .remember {
        color: #96A6C2;
        font-size: 14px;
        margin-left: 6px;
        border-radius: 5px;
      }
    }
    .submit {
      width: 100%;
    }
  }
  /deep/ .el-input__icon {
    font-size: 17px;
  }
}
</style>
