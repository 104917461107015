/*
 * @Date: 2022-05-06 10:14:27
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-05-10 17:06:27
 * @description: file content
 * @FilePath: /case-platform-frontend/src/views/user/api.js
 */
import { http } from '@/plugins/axios'

// 登录
const loginApi = (params) =>
  http.post('/api/v1/users/login', params )

// 获取当前用户信息
const getUserInfoApi = () =>
  http.get('/api/v1/users/current' )

// 登出
const logoutApi = () =>
  http.post('/api/v1/users/logout' )

export { loginApi, getUserInfoApi, logoutApi }
