/*
 * @Date: 2022-04-20 16:49:25
 * @author: xiebin
 * @LastEditors: xiebin
 * @LastEditTime: 2022-04-20 17:00:54
 * @description: file content
 * @FilePath: /case-platform-frontend/src/views/case-manage/case-standing/router.js
 */

// 案件台账列表
const List = () => import('./List.vue')

export default {
  path: 'standing',
  name: 'standingList',
  component: List
}
